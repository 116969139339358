import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"
import BGImage from "gatsby-background-image"

const BackgroundImage = ({ src, ...props }) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { internal: { mediaType: { regex: "images/" } } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const match = useMemo(
    () => data.allFile.edges.find(({ node }) => src === node.relativePath),
    [data, src]
  )

  return <BGImage fluid={match.node.childImageSharp.fluid} {...props} />
}

export default BackgroundImage
